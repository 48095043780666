import '/assets/styles/sections/header/menu-desktop.scss';
import '/assets/styles/sections/header/menu-desktop-submenu.scss';

import { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from 'prop-types';

// Hooks
import useDebounce from '@wearetla/tla-essentials-tools/hooks/debounce';
import useVisible from '@wearetla/tla-essentials-tools/hooks/visible';

// Context
import { useChildren } from '/utilities/children';
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';

// Partials
import Placeholder from '/views/partials/placeholder';
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Img from '@wearetla/tla-essentials-tools/partials/img';

// Data
import ageGroups from '/data/age-groups'

export const DesktopMenu = () => {
	const _mounted = useRef(false);

	const { mobile } = useBreakpoints();
	const { categories } = useGlobalState();

	const [hoveredCategory, setHoveredCategory] = useState(false);

	const debouncedHoveredCategory = useDebounce(hoveredCategory, 250);

	useEffect(() => {
		_mounted.current = true;

		return(() => {
			_mounted.current = false;
		})
	}, [])

	return (
		<div className="header-menu">
			<nav className="menu-wrapper">
				{categories ?
					<>
						{categories.map((category, nth) => (
							<Link
								href="category"
								params={{slug: category.slug, id: category.id}}
								className="menu-item"
								key={nth}
								onClick={() => { setHoveredCategory(false); }}
								onMouseLeave={() => { if(_mounted.current) { setHoveredCategory(false); } }}
								onMouseEnter={() => { if(_mounted.current) { setHoveredCategory(category); } }}>
								{category.name}
							</Link>
						))}
					</>
					:
					<>
						{[false, false, false, false, false, false, false, false, false, false, false, false].map((k , nth) => (
							<Placeholder className="menu-item" key={`dummy-${nth}`} />
						))}
					</>
				}
			</nav>
			<SubMenu
				mobile={mobile}
				category={debouncedHoveredCategory}
				onMouseEnter={() => { if(_mounted.current) { setHoveredCategory(debouncedHoveredCategory); } }}
				onMouseLeave={() => { if(_mounted.current) { setHoveredCategory(false); } }} />
		</div>
	)
}

const SubMenu = ({ category: categoryProp, onMouseLeave, onMouseEnter }) => {
	const _mounted = useRef(false);

	const { setChildSelection } = useChildren();
	const [inDom, visible] = useVisible(!!categoryProp, 300);
	const [category, setCategory] = useState(false);

	const categories = useMemo(() => {
		if(category) {
			let group2 = [...category.children];
			const limit = Math.ceil(category.children.length / 2);
			const group1 = group2.splice(0, (limit));

			return [group1, group2];
		}
		else {
			return [[], []];
		}
	}, [category])

	useEffect(() => {
		if(categoryProp) {
			if(_mounted.current) {
				setCategory(categoryProp);
			}
		}
		else if(!inDom) {
			if(_mounted.current) {
				setCategory(false);
			}
		}
	}, [categoryProp, inDom])

	useEffect(() => {
		_mounted.current = true;

		return () => {
			_mounted.current = false;
		}
	}, [])

	if(inDom) {
		return (
			<div
				className={'header-submenu' + (visible ? ' show' : '')}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}>
				<div className="submenu-wrap">
					<div className="submenu-left">
						<div className="left-section wide">
							<strong className="section-title">Kategoriler</strong>
							<div className="section-list">
								<div className="list-row">
									{categories[0].map((category) => (
										<div className="row-col" key={category.id}>
											<Link
												onClick={() => { onMouseLeave(false); }}
												href="category"
												params={{ slug: category.slug, id: category.id }}
												className="col-link">
												{category.name}
											</Link>

											{category.children && category.children.length > 0 &&
												<ul className="col-subs">
													{category.children.map((subCat) => (
														<li key={subCat.id}>
															<Link
																onClick={() => { onMouseLeave(false); }}
																href="category"
																params={{ slug: subCat.slug, id: subCat.id }}
																className="col-link">
																{subCat.name}
															</Link>
														</li>
													))}
												</ul>
											}
										</div>
									))}
								</div>
								<div className="list-row">
									{categories[1].map((category) => (
										<div className="row-col" key={category.id}>
											<Link
												onClick={() => { onMouseLeave(false); }}
												href="category"
												params={{ slug: category.slug, id: category.id }}
												className="col-link">
												{category.name}
											</Link>

											{category.children && category.children.length > 0 &&
												<ul className="col-subs">
													{category.children.map((subCat) => (
														<li key={subCat.id}>
															<Link
																onClick={() => { onMouseLeave(false); }}
																href="category"
																params={{ slug: subCat.slug, id: subCat.id }}
																className="col-link">
																{subCat.name}
															</Link>
														</li>
													))}
												</ul>
											}
										</div>
									))}
								</div>
							</div>
						</div>
						{category.id !== 4 &&
							<div className="left-section">
								<strong className="section-title">Cinsiyete Göre</strong>
								<div className="section-list">
									<div className="list-row">
										<div className="row-col">
											<Link
												href="category"
												onClick={() => {
													setChildSelection(false);
													onMouseLeave(false);
												}}
												params={{
													slug: category.slug,
													id: category.id,
													query: {
														gender_type: 2,
													}
												}}
												className="col-link">
												Kız Bebek
											</Link>
										</div>
										<div className="row-col">
											<Link
												href="category"
												onClick={() => {
													setChildSelection(false);
													onMouseLeave(false);
												}}
												params={{
													slug: category.slug,
													id: category.id,
													query: {
														gender_type: 1,
													}
												}}
												className="col-link">
												Erkek Bebek
											</Link>
										</div>
										<div className="row-col">
											<Link
												href="category"
												onClick={() => {
													setChildSelection(false);
													onMouseLeave(false);
												}}
												params={{
													slug: category.slug,
													id: category.id,
													query: {
														gender_type: 3,
													}
												}}
												className="col-link">
												Unisex
											</Link>
										</div>
									</div>
								</div>
							</div>
						}
						<div className="left-section">
							<strong className="section-title">Yaşa Göre</strong>
							<div className="section-list">
								<div className="list-row">
									{ageGroups.map((group, nth) => (
										<div className="row-col" key={nth}>
											{(group && category) &&
												<Link
													href="category"
													onClick={() => {
														setChildSelection(false);
														onMouseLeave(false);
													}}
													params={{
														slug: category.slug,
														id: category.id,
														query: {
															age_range: group.searchValue,
														}
													}}
													// href="customList"
													// params={{
													// 	...group.list,
													// 	query: {
													// 		categories: category.id,
													// 	}
													// }}
													className="col-link">
													{group.label}
												</Link>
											}
										</div>
									))}
								</div>
							</div>
						</div>
					</div>

					<div className="submenu-right">
						{(category.banners && category.banners.length > 0) &&
							<ul className="right-banners">
								{category.banners.map((banner) => (
									<li className="banners-banner" key={banner.id}>
										<Link
											raw
											onClick={() => { onMouseLeave(false); }}
											href={banner.link}
											className="banner-link">
											<div className="banner-imagewrap">
												<Img
													className="banner-image"
													alt={banner.title}
													title={banner.title}
													src={banner.url}
													cover />
											</div>
										</Link>
									</li>
								))}
							</ul>
						}

						{category.brands && category.brands.length > 0 &&
							<div className="right-brands">
								<strong className="brands-title"><span>Popüler Markalar</span></strong>

								<ul className="brands-list">
									{category.brands.map((brand) => (
										<li className="list-brand" key={brand.id}>
											<Link
												onClick={() => { onMouseLeave(false); }}
												href="category"
												params={{
													id: category.id,
													slug: category.slug,
													query: {
														brands: [brand.id],
													}
												}}
												className="brand-link"
												title={brand.name}>
												<div className="brand-imagewrap">
													<Img
														className="brand-image"
														src={brand.image}
														alt={brand.name} />
												</div>
											</Link>
										</li>
									))}
								</ul>
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
	else {
		return false;
	}
}

SubMenu.propTypes = {
	category: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([false])]),
	onMouseLeave: PropTypes.func,
	onMouseEnter: PropTypes.func,
}

export default DesktopMenu;